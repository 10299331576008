import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import * as styles from "./MainSlider.module.scss";
import ArrowRight from "../../../svg/icon-arrow-right.svg";
import ArrowLeft from "../../../svg/icon-arrow-left.svg";
const MainSlider = ({data=[], title, description, slidesNumber, height, slideWidth, extraClass}) => {
  const [width, setWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setWidth(window.innerWidth);

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500); 

    return () => clearTimeout(timer);
  }, []); 

  const settings = {
    centerMode: width >= 768 ? true : false,
    slidesToShow: slidesNumber || 1,
    infinite: true,
    dots: false,
    arrows: true,
    prevArrow: <div><ArrowLeft/></div>,
    nextArrow: <div><ArrowRight /></div>,
    centerPadding: width >= 768 ? '25%' : '0%',
  };

  if (isLoading) {
    return null;
  }
  return (
    <div className={`${styles.container} ${styles[extraClass]} spaceSlider connectlider`}>
      <h1>{title}</h1>
      <p className={styles.desc}>{description}</p>
      <Slider {...settings}>
        {data?.map((item, i) => (
          <div className={styles.item} key={i}>
            <img src={item.image} style={{ height: height || "", width: slideWidth || "100%" }} alt="slider" />
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default MainSlider;